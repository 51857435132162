import { HighLightedArticle } from '/features/buildingBlocks/HighLightedArticle'
import { ArticlesOverview } from '/features/buildingBlocks/ArticlesOverview'
import { ContainerMd, ContainerXl } from '/features/buildingBlocks/Container'

import styles from './NieuwsUitHetNotariaat.css'

export function NieuwsUitHetNotariaat({ articles }) {
  const [firstArticle, ...otherArticles] = articles
  return (
    <div className={styles.component}>
      <ContainerMd>
        <HighLightedArticle article={firstArticle} />
      </ContainerMd>
      <ContainerXl>
        <ArticlesOverview articles={otherArticles}  />
      </ContainerXl>
    </div>
  )
}
