import { useLanguage, useTranslate } from '/machinery/I18n'
import { useEnteredState } from '/machinery/useEnteredState'
import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ReadingTime } from '/features/buildingBlocks/ReadingTime'
import { TextSecondaryMd } from '/features/buildingBlocks/Text'
import { toPlainText } from '@portabletext/react'
import { routeMap } from '/routeMap'
import { LinkButtonGhost } from '/features/buildingBlocks/Link'
import lightning from '/images/lightning.png'

import styles from './HighLightedArticle.css'

export function HighLightedArticle({ article }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { ref: hoverRef, hovered } = useEnteredState()

  return (
    <article ref={hoverRef} className={styles.component} data-x='highlighted-article'>
      <div className={cx(styles.content, styles.relativeToParent)}>
        <div className={cx(styles.text, styles.relativeToParent)}>
          <HeadingMd h={2}>{toPlainText(article.title)}</HeadingMd>
          <TextSecondaryMd>
            <p className={styles.lineClamp}>{article.introText}</p>
          </TextSecondaryMd>
          <LinkButtonGhost
            href={routeMap.app.articles.article({
              language,
              slug: article.slug.current,
            })}
            dataX='lees-meer'
            relativeToParent
          >
            {__`lees-meer`}
          </LinkButtonGhost>
        </div>
        <ReadingTime
          layoutClassName={styles.readingTime}
          readingTime={article.readingTime}
        />
      </div>
      <div className={cx(styles.imageLayout, styles.aspectRatio, hovered && styles.isHovered)}>
        <ImageCover aspectRatio={4 / 5} image={article.image} imgProps={{ loading: 'lazy' }} />
      </div>
      <div className={styles.outerDecoration}>
        <img className={styles.decoration} src={lightning} alt={''} />
      </div>
    </article>
  )
}
